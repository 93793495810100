import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { MainTitle } from "./common/text"
import { LineWrapper } from "./common/containers"
import { UnstyledList } from "./common/essentials"

import SiteLanguage from "../../helpers/siteLanguage"

const ACFQuery = graphql`
  {
    wp {
      partners {
        en {
          partnersTitle {
            textGray
            textGreen
          }
          partnersLogos {
            altText
            atttachement_pl {
              altTextPl
            }
            id
            localFile {
              publicURL
            }
          }
        }
        pl {
          partnersTitlePl {
            textGrayPl
            textGreenPl
          }
          partnersLogosPl {
            altText
            atttachement_pl {
              altTextPl
            }
            id
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

const defaultContainerStyle = {
  "--does-bold-line-exists": "none",
}

const PartnersContainer = styled(LineWrapper)`
  padding-top: 80px;
  padding-bottom: 80px;

  @media ${maxD.laptop} {
    align-items: flex-start;
    flex-direction: column;
  }

  @media ${maxD.mobileM} {
    padding-bottom: 40px;
  }
`

const PartnersTitle = styled(MainTitle)`
  @media ${minD.laptop} {
    padding-right: 60px;
  }
`

const PartnersList = styled(UnstyledList)`
  display: flex;
  flex-wrap: wrap;

  flex-shrink: 0;
  margin: 0 -37px;

  @media ${minD.laptop} {
    width: 700px;
  }

  @media ${maxD.laptop} {
    margin: 1.5rem -37px 0;
    max-width: 700px;
  }

  @media ${maxD.tablet} {
    margin: 1.8rem -15px 0;
  }
`

const Partner = styled.li`
  width: calc(25% - 74px);
  margin: 16px 37px;

  @media ${maxD.tablet} {
    width: calc(25% - 30px);
    margin: 13px 15px;
  }

  @media ${maxD.mobileM} {
    width: calc(33.33% - 30px);
    margin: 12px 15px;
  }
`

const PartnerLogo = styled.img`
  width: 100%;
  height: 52px;
  object-fit: contain;
`

const Partners = ({ style }) => {
  const acfData = useStaticQuery(ACFQuery)
  const enData = acfData.wp.partners.en
  const plData = acfData.wp.partners.pl

  let header, partners

  if (SiteLanguage() === "en") {
    header = {
      gray: enData.partnersTitle.textGray,
      green: enData.partnersTitle.textGreen,
    }

    partners = enData.partnersLogos
  } else {
    header = {
      gray: plData.partnersTitlePl.textGrayPl,
      green: plData.partnersTitlePl.textGreenPl,
    }

    partners = plData.partnersLogosPl
  }

  return (
    <PartnersContainer
      flex
      alignItems="c"
      style={{ ...defaultContainerStyle, ...style }}
    >
      <PartnersTitle style={{ "--padding-bottom": "0" }}>
        {header.gray}
        <span>{header.green}</span>
      </PartnersTitle>
      <PartnersList>
        {partners &&
          partners.map(partner => (
            <Partner key={partner.id}>
              <PartnerLogo
                src={partner.localFile.publicURL}
                alt={(SiteLanguage() !== "en" && partner.atttachement_pl?.altTextPl) ? partner.atttachement_pl?.altTextPl : partner.altText}
              />
            </Partner>
          ))}
      </PartnersList>
    </PartnersContainer>
  )
}

export default Partners
